import React from 'react';
//import logo from './logo.svg';
import './App.css';
//import * as ReactBootstrap from 'react-bootstrap';
//import Amplify, { Auth } from 'aws-amplify';
//import awsconfig from './aws-exports';
//import { AmplifyAuthenticator, withAuthenticator, AmplifySignOut, AmplifySignIn } from '@aws-amplify/ui-react';
//import Authentication from './Authentication.js';
import Content from './Content.js';
import Header from './Header.js';
import Footer from './Footer.js';
//import { Button, Container, Row, Col } from 'bootstrap-4-react';

import { BrowserRouter as Router} from 'react-router-dom';

const App = () => {
    return (
        <> 
            <Router> 
                <Header />
                <Content />
                <Footer />
            </Router>
        </>
    );
}

export default App;
