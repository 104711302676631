import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { environment } from './config';

class Footer extends React.Component {


    render() {

        const rowStyle = {
            height: '4rem',
            backgroundColor: 'rgba(41, 43, 44, 0.1)',
            //marginTop: '1rem'
        };

        const textStyle = {
            textAlign: "center"
        }

        // class="fixed-bottom" 

        return (
            <Container fluid="lg">
                <Row style={rowStyle}>
                    <Col>
                        <p />
                        <p style={textStyle}>&copy; <a href="https://www.easypeasydigital.co.uk">easypeasyDigital</a> 2022  {environment}</p>
                    </Col>
                </Row>         
            </Container>

        )
    }
}


export default Footer;