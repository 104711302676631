import React, {useState}  from 'react';
import { Container, Row, Col, Navbar, Nav,NavDropdown, NavItem, Form, FormControl, Button, Modal} from 'react-bootstrap';

import { NavLink, withRouter, useLocation } from 'react-router-dom';
import { Auth, Hub, loadingBarFill } from 'aws-amplify';


//let lastLocation = 'none'

//class NavConfirm extends React.Component {
//class NavConfirm extends React.Component {

const NavConfirm = (props) => {
    //let history=useLocation();

    const handleClose = () => { props.history.push(props.pathNo) }


    return (
    <>

        <Modal    
            show={true} 
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="sm"
        >
        <Modal.Header className="text-center"
            closeButton
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Modal.Title className="text-center">{props.heading}</Modal.Title>
        </Modal.Header>

        <Modal.Body className="text-center">{props.description}</Modal.Body>
        
        <Modal.Footer 
            style={{
            display: "flex",
            justifyContent: "center",
            }}
        >

            <Nav.Link as={NavLink} to={props.pathNo}><Button variant="secondary">No</Button></Nav.Link>
            <Nav.Link as={NavLink} to={props.pathYes}><Button variant="primary">Yes</Button></Nav.Link>

        </Modal.Footer>
        </Modal>

    </>
    );
}

  
export default NavConfirm; 