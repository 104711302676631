import React from 'react';

import { Amplify } from 'aws-amplify';
import { awsconfig } from './config';

import './App.css';




import {Authenticator, useAuthenticator, AmplifyAuthContainer, AmplifyAuthenticator, AmplifySignUp, AmplifySignOut, AmplifySignIn } from '@aws-amplify/ui-react';

import '@aws-amplify/ui-react/styles.css';

Amplify.configure(awsconfig);





const Authentication = () => {
//function Authentication() {
    //console.log('XXXXX Auth', useAuthenticator());
    return (
        <>
      <Authenticator 
        loginMechanisms={['email']}
        hideSignUp = {true}
        >

      </Authenticator>
      </>

    );
  }

export default Authentication
