// pages/Home.js
import './App.css';
import React, { useEffect, useState } from "react";
import { Form, Option, FormSelect, FormControl, ListGroup, Container, Row, Col, Card, Button, Spinner} from 'react-bootstrap';
import { useAnalyticsApi,useAuthorize,useSignOut} from "react-use-analytics-api";
import { AnalyticsDashboard, SessionsByDateChart, SessionsGeoChart, SessionsBySourceChart, SessionsByHourChart, PageViewsPerPathChart,   AuthorizeButton, SignOutButton, ViewSelector, } from "react-analytics-charts";
// Over ten different commonly used charts are available
import {ApiGet} from './Api';
import { CardHeaderLoading } from './CardServices';
import {AnalyticsStart, WebAnalytics, EpdSessionsByDateChartEnhanced, EpdBounceRateChart, EpdSessionsByUserTypeChart, EpdPagesPerSessionChart, EpdOrganicSearchesChart, EpdSessionDurationChart, EpdActiveUsersChart, EpdSessionsByDateChart, EpdSessionsGeoChart, EpdPageViewsPerPathChart, EpdSessionsByDeviceCategoryChart, EpdSessionsBySourceChart} from './GoogleServices';
import {UptimeFirstStageContent} from './Epduptime';
import {Website} from './Epdsites.js';
import {BackupFirstStageContent} from './Epdbackupstatus'
import {AnalyticsMergeToData, UptimeMergeToData, BackupMergeToData} from './Library'

import {pad, timedateConvert, daysOld, byteToMegabyte, secondstotime} from './Library';

import {EpdFullApiLoad} from './Library';

const cardinfo = "d-flex justify-content-center"; //justify-center


// this code is based in Epuptime.js but is a single vertical record
class UptimeInformation extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

      if (this.props.site.length === 0) { // if no data then do nothing
          return (null)
      }

 
      const firststage = UptimeFirstStageContent(this.props.site);

     

      const listing = ( 
        <>
          <tr className="UptimeSingleStatusRow" key={1}><th className="UptimeSingleStatusTitle" colSpan="2">Uptime Status</th></tr>
          <tr className="UptimeSingleStatusRow" key={2}><th className="UptimeSingleStatusTitle">Description</th><th className="UptimeSingleStatusTitle" >Value</th></tr>
      
          <tr className="UptimeSingleStatusRow" key={3}><th className="UptimeSingleStatusTitle">Status</th><td className={firststage.TDstatus}>{firststage.status}</td></tr>


          <tr className="UptimeSingleStatusRow" key={4}><th className="UptimeSingleStatusTitle">% Up:  1 Day </th><td className={firststage.TDuptimes[0]}>{(firststage.status !== "") && parseFloat(firststage.uptimes[0]).toFixed(2)}</td></tr>
          <tr className="UptimeSingleStatusRow" key={5}><th className="UptimeSingleStatusTitle">% Up:  7 Days</th><td className={firststage.TDuptimes[1]}>{(firststage.status !== "") && parseFloat(firststage.uptimes[1]).toFixed(2)}</td></tr>
          <tr className="UptimeSingleStatusRow" key={6}><th className="UptimeSingleStatusTitle">% Up: 30 Days</th><td className={firststage.TDuptimes[2]}>{(firststage.status !== "") && parseFloat(firststage.uptimes[2]).toFixed(2)}</td></tr>

          <tr className="UptimeSingleStatusRow" key={7}><th className="UptimeSingleStatusTitle">Time&#8595;:  1 Day </th><td className={firststage.TDdurations[0]}>{firststage.durations[0]}</td></tr>
          <tr className="UptimeSingleStatusRow" key={8}><th className="UptimeSingleStatusTitle">Time&#8595;:  7 Days</th><td className={firststage.TDdurations[1]}>{firststage.durations[1]}</td></tr>
          <tr className="UptimeSingleStatusRow" key={9}><th className="UptimeSingleStatusTitle">Time&#8595;: 30 Days</th><td className={firststage.TDdurations[2]}>{firststage.durations[2]}</td></tr>
        </>
      )
      


      const output = [
          <table className="UptimeSingleStatusTable" key="UptimeStatusTable">
              <tbody>
                  {listing}
              </tbody>
          </table>
      ]



      return (
          <>
            {output}
          </>
      )
  }

}

class UptimeStatus extends React.Component {
  constructor(props) {
    super(props);
  }

  render () {

  this.link = "https://"+this.props.url;  

  return (
      <>
        <Card style={{width: 300}}>
          <CardHeaderLoading loading={false} title={<a href={this.link}>{this.props.url}</a>}/>
          <Card.Body className={cardinfo} style={{width: 300, height: 300, padding:0}}>

            <UptimeInformation site={this.props.site} />
          </Card.Body>
        </Card>
      </>
    )
  }
};


// this code is based in Epbackupstatus.js but is a single vertical record
class BackupInformation extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

      if (this.props.site.length === 0) { // if no data then do nothing
          return (null)
      }

 
      const firststage = BackupFirstStageContent(this.props.site);

     

      const listing = ( 
        <>
          <tr className="BackupSingleStatusRow" key={1}><th className="BackupSingleStatusTitle" colSpan="2">Backup Status</th></tr>
          <tr className="BackupSingleStatusRow" key={2}><th className="BackupSingleStatusTitle">Description</th><th className="BackupSingleStatusTitle" >Value</th></tr>
      
          <tr className="BackupSingleStatusRow" key={3}><th className="BackupSingleStatusTitle">Latest: MySQL</th><td className={firststage.TDnotziplmDays}>{firststage.notzipLastModified}</td></tr>
          <tr className="BackupSingleStatusRow" key={4}><th className="BackupSingleStatusTitle">Latest: Other</th><td className={firststage.TDziplmDays}>{firststage.zipLastModified}</td></tr>
          <tr className="BackupSingleStatusRow" key={5}><th className="BackupSingleStatusTitle">Oldest: MySQL</th><td className={firststage.TDnotzipfmDays}>{firststage.notzipFirstModified}</td></tr>
          <tr className="BackupSingleStatusRow" key={6}><th className="BackupSingleStatusTitle">Oldest: Other</th><td className={firststage.TDzipfmDays}>{firststage.zipFirstModified}</td></tr>

          <tr className="BackupSingleStatusRow" key={7}><th className="BackupSingleStatusTitle">Files: MySQL</th><td className="number">{firststage.notzipFileCount}</td></tr>
          <tr className="BackupSingleStatusRow" key={8}><th className="BackupSingleStatusTitle">FilesL Other</th><td className="number">{firststage.zipFileCount}</td></tr>
          <tr className="BackupSingleStatusRow" key={9}><th className="BackupSingleStatusTitle">FIles: Size (MB)</th><td className="number">{firststage.Size}</td></tr>

        </>
      )
      


      const output = [
          <table className="BackupSingleStatusTable" key="BackupStatusTable">
              <tbody>
                  {listing}
              </tbody>
          </table>
      ]



      return (
          <>
            {output}
          </>
      )
  }

}


class BackupStatus extends React.Component {
  constructor(props) {
    super(props);
  }

  render () {

  this.link = "https://"+this.props.url;  

  return (
      <>
        <Card style={{width: 300}}>
          <CardHeaderLoading loading={false} title={<a href={this.link}>{this.props.url}</a>}/>
          <Card.Body className={cardinfo} style={{width: 300, height: 300, padding:0}}>

            <BackupInformation site={this.props.site} />
          </Card.Body>
        </Card>
      </>
    )
  }
};

class SummaryList extends React.Component { 
  constructor(props) {
      super(props);
      this.onChange = this.onChange.bind(this);
      this.setState = this.setState.bind(this);
      this.state = { site: null };
      this.options = this.props.data.map((record) => {
        return [<option key={record.domain} value={record.domain}>{record.domain}</option>];
    });

  }


  
  onChange = (event) => {
    console.log('change', event.target.value)
    const site = this.props.data.find(site => site.domain === event.target.value);
    if (site === undefined) { 
      this.setState({'site': null});
    } else {
      this.setState({'site': site});
    }
    console.log('result', site, this.state.site);
  };


  render () {

    //const url  =  ((this.state.site !== null) && (this.state.site.domain != undefined)) ? this.state.site.domain : "";
    const url  =  ((this.state.site !== null) && (this.state.site.domain != undefined)) ? this.state.site.domain : "";

    const urlFound =  (url !== "");
  
    
    const analyticFound = urlFound && this.state.site.hasOwnProperty('analytic');
    const backupFound   = urlFound && this.state.site.hasOwnProperty('backup');
    const monitorFound  = urlFound && this.state.site.hasOwnProperty('monitor');


   // const view =  ((this.state.site !== null) && analyticFound)  ? this.state.site.analytic.view   : "";

      //const view =  ((this.state.site !== null) && (this.state.site.view != undefined))   ? this.state.site.view   : "";
    const view =  ((this.state.site !== null) && analyticFound)  ? this.state.site.analytic.view   : "";

    const cardinfo = "d-flex justify-content-center"; //justify-center

   // const view = record.hasOwnProperty('analytic') ? record.analytic.view : ""; 
    console.log('item', url, view);


  this.delay=0;
  //     <Container fluid="lg" className="content_container">
  return (
 
  <>
  <Container>
      
         <Row style={{padding: 20}}> 
            <Col></Col>
            <Col md="auto">
                <Form.Control as="select" custom onChange={this.onChange}>    
                  <option value="">Select Site</option>
                    {this.options}
                </Form.Control>
            </Col>
            <Col></Col>
          </Row> 
          <Row className={cardinfo}>

              { monitorFound && <UptimeStatus url={url} site={this.state.site}/> }
              { backupFound && <BackupStatus url={url} site={this.state.site}/> }
              { urlFound && <Website url={url}/> }

              {/*{ url !== "" && <WebAnalytics Component={EpdSessionsByDateChartEnhanced} url={url} gapi={this.props.gapi} days={28} view={view} delay={this.delay++}/> }*/}



              { analyticFound && <WebAnalytics Component={EpdSessionsByDateChartEnhanced} url={url} gapi={this.props.gapi} chartTitle={'Users'} days={28} view={view} delay={this.delay++}/> }
              { analyticFound && <WebAnalytics Component={EpdSessionsByDateChartEnhanced} url={url} gapi={this.props.gapi} chartTitle={'Pages'} days={28} view={view} delay={this.delay++}/> }
              { analyticFound && <WebAnalytics Component={EpdSessionsByDateChartEnhanced} url={url} gapi={this.props.gapi} chartTitle={'Sessions'} days={28} view={view} delay={this.delay++}/> }


              { analyticFound && <WebAnalytics Component={EpdSessionsByDateChartEnhanced} url={url} gapi={this.props.gapi} chartTitle={'Users'} days={90} view={view} delay={this.delay++}/> }
              { analyticFound && <WebAnalytics Component={EpdSessionsByDateChartEnhanced} url={url} gapi={this.props.gapi} chartTitle={'Pages'} days={90} view={view} delay={this.delay++}/> }
              { analyticFound && <WebAnalytics Component={EpdSessionsByDateChartEnhanced} url={url} gapi={this.props.gapi} chartTitle={'Sessions'} days={90} view={view} delay={this.delay++}/> }


              { analyticFound && <WebAnalytics Component={EpdBounceRateChart} url={url} gapi={this.props.gapi} view={view} delay={this.delay++}/> }
              { analyticFound && <WebAnalytics Component={EpdActiveUsersChart} url={url} gapi={this.props.gapi} view={view} delay={this.delay++}/> }
             {/*} { !analyticFound && <WebAnalytics Component={EpdSessionsByDateChartEnhanced} url={url} gapi={this.props.gapi} days={90} view={view} delay={this.delay++}/> }*/}
              { analyticFound && <WebAnalytics Component={EpdOrganicSearchesChart} url={url} gapi={this.props.gapi}  view={view} delay={this.delay++}/> }    

              { analyticFound && <WebAnalytics Component={EpdSessionsByUserTypeChart} url={url} gapi={this.props.gapi}  view={view} delay={this.delay++}/> }
              { analyticFound && <WebAnalytics Component={EpdSessionsByDeviceCategoryChart} url={url} gapi={this.props.gapi}  view={view} delay={this.delay++}/> }
              { analyticFound && <WebAnalytics Component={EpdSessionsBySourceChart} url={url} gapi={this.props.gapi}  view={view} delay={this.delay++}/> }
             
              { analyticFound && <WebAnalytics Component={EpdPagesPerSessionChart} url={url} gapi={this.props.gapi}  view={view} delay={this.delay++}/> }
              { analyticFound && <WebAnalytics Component={EpdSessionDurationChart} url={url} gapi={this.props.gapi}  view={view} delay={this.delay++}/> }
              { analyticFound && <WebAnalytics Component={EpdSessionsGeoChart} url={url} gapi={this.props.gapi}  view={view} delay={this.delay++}/> }


                       
          </Row></Container>
        </>
    )
  } 
}  





/*
/*
{ url !== "" && <Website url={url}/> }

}
              

*//*

           

          <Card>
              <CardHeaderLoading loading={false} title={<a href={url}>{title}</a>}/>
              <Card.Body className={cardinfo} style={{width: 300, height: 300}}>
                  <EpdSessionsByDateChart gapi={this.props.gapi} view={view} />   
                </Card.Body>
            </Card>

*/

class Details extends React.Component { 
  constructor() {
      super();
      //this.state = { user: [], data: [], analyticsMerged: false,  gapi: null };
      this.state = { analyticsMerged: false, localData: [], gapi: null }; // use localcopy of data because this module changes it
      this.setGapi = this.setGapi.bind(this);
      this.setState = this.setState.bind(this);
  }

/*
  
  componentDidMount() {
      Api('sites')
          .then(res => {
              const sites = res;
              Api('uptime') 
                  .then(res => {
                      this.setState({data: UptimemergeToData(res.monitors, sites)}) // merges the monitors into the sites array
                  })
                  .catch(err => console.log('Api Epduptime uptime', err));       
          })
          .catch(err => console.log('Api Epduptime sites', err));
  }   
 */       

  //componentDidMount() {
  //  EpdFullApiLoad(this.setState);
  //}

  /*
  componentDidMount() {
    ApiGet('domain')
      .then(res => {
        const domains = res.Items;
        ApiGet('google')                                                    // gets the user information 
          .then(res => { 
            this.setState({ user: res });
            ApiGet('backup')                  
              .then(res => {
                const dataWithBackups= BackupMergeToData(res, domains);
                ApiGet('uptime') 
                  .then(res => {
                      this.setState({domains: UptimeMergeToData(res.monitors, dataWithBackups)}) // merges the monitors into the sites array
                  })
                  .catch(err => console.log('Api Details uptime', err));       
              })
              .catch(err => console.log('Api Details backup', err));
          })
          .catch(err => console.log('Api Details google token', err));
        })
        .catch(err => console.log('Api Details domain', err));
      }
      */
/*
  componentDidMount() {
    Api('google')                                                    // gets the user information 
      .then(res => { 
        this.setState({ user: res });
        Api('status')                                                       // gets a list of sites WITH BACKUP IMFORMATION
          .then(res => {this.setState({ data: res }); this.setState({ data: res });})  
      })
  }
*/
  // called by a child when gapi is known
  setGapi(gapi) {
    const newGapi = gapi;
    this.setState({gapi: gapi});
    const res = gapi.client.analytics.management.accountSummaries.list()         // gets a list of analytics accounts
      //.then(res => this.setState({data: AnalyticsMergeToData(res.result.items, this.state.domains)}) // merges the items into the data array
      .then(res => this.setState({gapi: newGapi, analyticsMerged: true, localData: AnalyticsMergeToData(res.result.items, this.props.state.data)}) // merges the items into the data array
      )
  }



  render () {

    const textStyle = { 
      textAlign: "center"
    };
  
    console.log('Details render',this.state, this.props.state);


    return (
        <Container fluid="lg" className="content_container">
          <Row>
            <Col>
              <h1 style={textStyle}>Details</h1>
              { (this.props.state.data.length === 0) && (this.state.gapi === null) && <div style={textStyle}><Spinner  animation="border" /></div> }
            </Col>
          </Row>
          <Row>
              { (this.props.state.data.length > 0) && <AnalyticsStart user={this.props.state.user} data={this.props.state.data} setGapi={this.setGapi}/>}  
              { (this.state.analyticsMerged) && <SummaryList data={this.state.localData} gapi={this.state.gapi}/> }

          </Row>
        </Container>
    ) 
  }
}



/*

           <Col>
              <h1 style={textStyle}>Details</h1>
              { (this.state.data.length === 0) && (this.state.gapi === null) && <div style={textStyle}><Spinner  animation="border" /></div> }
            </Col>
          </Row>
          <Row>
              { (this.state.data.length > 0) && <AnalyticsStart user={this.state.user} data={this.state.data} setGapi={this.setGapi}/>}  
          </Row>
        </Container>

*/
//               { ((this.state.gapi !== null) && (this.state.data.length > 0) && ((analyticsMerged))) && <SummaryList data={this.state.data} gapi={this.state.gapi}/> }

/*

class TestSummary extends React.Component {
    constructor() {
        super();
        //this.state = { data: [] };
     
    }
  
  //  componentDidMount() {
  //    Api('sites') // this is the API data fetched and later displayed by Status Information
  //      .then(res => this.setState({ data: res }));
  //  }
  
   
    render () {
  
      const textStyle = {
        textAlign: "center"
      };

      const urlOn = "https://accounts.google.com/o/oauth2/v2/auth?access_type=online&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fanalytics.readonly&response_type=code&client_id=452884231082-0olhnu1petof6v41milda33irvpiim46.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocalhost%3A3000";
      const urlOff = "https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fanalytics.readonly&response_type=code&client_id=452884231082-0olhnu1petof6v41milda33irvpiim46.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocalhost%3A3000";

      const urlOffhack = "https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https://www.googleapis.com%2Fauth%2Fanalytics.readonly&response_type=code&client_id=452884231082-0olhnu1petof6v41milda33irvpiim46.apps.googleusercontent.com&redirect_uri=http://localhost:3000";

      const url = "https://www.googleapis.com/oauth2/v3/tokeninfo?access_token=";

      const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjEwNjM2MzIzNTU5MzI3NDYxNzY0NSJ9.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20vby9vYXV0aDIvYXV0aCIsInN1YiI6Imh0dHBzOi8vYWNjb3VudHMuZ29vZ2xlLmNvbS9vL29hdXRoMi9hdXRoIiwiYXVkIjoiZXBkc3RhdHVzIiwiaWF0IjoxNjM4Mjk4OTcyLCJleHAiOjE2MzgzMDI1NzJ9.iXi8kiratutELrRtuS6Pkjld0xEWJkcX_-EGzfDej7w";
    
      if (false) { 

      //if (this.state.data.length === 0) { 
  
        return (
            <Container fluid="lg" className="content_container">
              <Row>
                <Col>
                  <h1 style={textStyle}>Summary</h1>
                  <div style={textStyle}><Spinner  animation="border" /></div>
                </Col>
              </Row>
            </Container>
        )
      }
      else {

        return (
            <Container fluid="lg" className="content_container">
              <Row>
                <Col>
                  <h1 style={textStyle}>Summary</h1>
                  <Button onClick={(e) => {window.location.href = urlOn}}>URL on</Button>
                  <Button onClick={(e) => {window.location.href = urlOff}}>URL off</Button>
                  <Button onClick={(e) => {window.location.href = urlOffhack}}>URL off</Button>
                  <Button onClick={(e) => {window.location.href = url+token}}>Service Token</Button>
                </Col>
              </Row>

            </Container>
        )
      }
    }
  }
  */


  export default Details;