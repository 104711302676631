// pages/Home.js
import './App.css';

import React from "react";


import { Container, Row, Col, Nav, Button, Image, Accordion, Card, CardGroup, ListGroup, Spinner} from 'react-bootstrap';


import {ApiGet} from './Api';
import { CardHeaderLoading } from './CardServices';

import {SortAndIndex} from './Library';

const textCenter = {
  textAlign: "center"
};

const textRight = {
  textAlign: "right"
};

const textLeft = {
  textAlign: "left"
};

const rowStyle = {
  // height: '100vw',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
 // marginBottom: '1rem'
  paddingTop: '1rem'
  //margin: 100 auto
};

const cardinfo = "d-flex justify-content-center"; //justify-center



class Website extends React.Component {
  constructor(props) {
    super(props);
    this.state = {loading: true, url: props.url};
  }

  static getDerivedStateFromProps(props, state) {
    
    if (props.url !== state.url) {
        return { loading: true, url: props.url };    
    }
    else
    {
      return null;
    }
  }

  render () {
  

  this.link = "https://"+this.props.url;
  

  return (
      <>
        <Card style={{width: 300}}>
          <CardHeaderLoading loading={this.state.loading} title={<a href={this.link}>{this.props.url}</a>}/>
          <Card.Body className={cardinfo} style={{width: 300, height: 300, padding:0}}><iframe src={this.link } width={300} height={300}  loading='lazy'onLoad={()=> this.setState({loading: false})}><p>Your browser does not support iframes.</p></iframe></Card.Body>
        </Card>
      </>

    )
  }
};



const SiteList = (props) => {

  const sites = props.data.map((record) => {
    return(<div key={record.domain}><Website url={record.domain}/></div>);
  })

  return (
    <>
      <Row className={cardinfo}> 
        {sites}
      </Row>
    </>
  )
  
};




class Epdsites extends React.Component {
  constructor() {
      super();
      //this.state = { data: [] };
  }
/*
  componentDidMount() {
    ApiGet('domain') // this is the API data fetched and later displayed by Status Information
      .then(res => this.setState({ data: SortAndIndex(res.Items) }));
  }
*/
  render () {

    const textStyle = {
      textAlign: "center"
    };
  

    if (this.props.state.data.length === 0) { 

      return (
          <Container fluid="lg" className="content_container">
            <Row>
              <Col>
                <h1 style={textStyle}>Web Sites</h1>
                <div style={textStyle}><Spinner  animation="border" /></div>
              </Col>
            </Row>
          </Container>
      )
    }
    else {

      return (
          <Container fluid="lg" className="content_container">
            <Row>
              <Col>
                <h1 style={textStyle}>Web Sites</h1>
              </Col>
            </Row>
            <Row>
                <SiteList data={this.props.state.data}/>
            </Row>
          </Container>
      )
    }
  }
}

export {Epdsites, Website};
