// pages/Home.js
import './App.css';
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button, Spinner} from 'react-bootstrap';
import { useAnalyticsApi,useAuthorize,useSignOut} from "react-use-analytics-api";
import { AnalyticsDashboard, SessionsByDateChart, BounceRateChart,OrganicSearchesChart, SessionsByUserTypeChart, PagesPerSessionChart, SessionDurationChart, ActiveUsersChart, SessionsGeoChart, SessionsBySourceChart, SessionsByHourChart, PageViewsPerPathChart, SessionsByDeviceCategoryChart,  AuthorizeButton, SignOutButton, ViewSelector, } from "react-analytics-charts";
// Over ten different commonly used charts are available
import Api from './Api';
import { CardHeaderLoading } from './CardServices';

var divCounter = 0; // used to create unique divs

const queryDateRange=
{
    'start-date': `28daysAgo`,
    'end-date': 'yesterday',
}


export const EpdPagesPerSessionChart= (props) => {

    const container = props.view+'_'+divCounter++;

    return (
        <>
            <div style={{ marginTop: "30px" }}>
                <div id={container}>
                    <PagesPerSessionChart
                        container={container}
                        options={{width: 290, height:250}}
                        gapi={props.gapi}
                        viewId={props.view}
                        query={queryDateRange}
                    />                 
                </div>
            </div>`
        </>
    )
}

export const EpdSessionsByUserTypeChart= (props) => {

    const container = props.view+'_'+divCounter++;

    return (
        <>
            <div style={{ marginTop: "30px" }}>
                <div id={container}>
                    <SessionsByUserTypeChart
                        container={container}
                        options={{width: 290, height:250}}
                        gapi={props.gapi}
                        viewId={props.view}
                        query={queryDateRange}
                    />                 
                </div>
            </div>`
        </>
    )
}


export const EpdSessionDurationChart= (props) => {

    const container = props.view+'_'+divCounter++;

    return (
        <>
            <div style={{ marginTop: "30px" }}>
                <div id={container}>
                    <SessionDurationChart
                        container={container}
                        options={{width: 290, height:250}}
                        gapi={props.gapi}
                        viewId={props.view}
                        query={queryDateRange}
                    />                 
                </div>
            </div>`
        </>
    )
}

export const EpdBounceRateChart= (props) => {

    const container = props.view+'_'+divCounter++;

    return (
        <>
            <div style={{ marginTop: "30px" }}>
                <div id={container}>
                    <BounceRateChart
                        container={container}
                        options={{width: 290, height:250}}
                        gapi={props.gapi}
                        viewId={props.view}
                        query={queryDateRange}
                    />                 
                </div>
            </div>`
        </>
    )
}

export const EpdOrganicSearchesChart= (props) => {

    const container = props.view+'_'+divCounter++;

    return (
        <>
            <div style={{ marginTop: "30px" }}>
                <div id={container}>
                    <OrganicSearchesChart
                        container={container}
                        options={{width: 290, height:250}}
                        gapi={props.gapi}
                        viewId={props.view}
                        query={queryDateRange}
                    />                 
                </div>
            </div>`
        </>
    )
}

export const EpdActiveUsersChart= (props) => {

    const container = props.view+'_'+divCounter++;

    return (
        <>
            <div style={{ marginTop: "30px" }}>
                <div id={container}>
                    <ActiveUsersChart
                        container={container}
                        options={{width: 290, height:250}}
                        gapi={props.gapi}
                        viewId={props.view}
                        query={queryDateRange}
                    />                 
                </div>
            </div>`
        </>
    )
}

export const EpdSessionsBySourceChart= (props) => {

    const container = props.view+'_'+divCounter++;

    return (
        <>
            <div style={{ marginTop: "30px" }}>
                <div id={container}>
                    <SessionsBySourceChart
                        container={container}
                        options={{width: 290, height:250}}
                        gapi={props.gapi}
                        viewId={props.view}
                        query={queryDateRange}
                    />                 
                </div>
            </div>`
        </>
    )
}

export const EpdSessionsByDeviceCategoryChart= (props) => {

    const container = props.view+'_'+divCounter++;

    return (
        <>
            <div style={{ marginTop: "30px" }}>
                <div id={container}>
                    <SessionsByDeviceCategoryChart
                        container={container}
                        options={{width: 290, height:250}}
                        gapi={props.gapi}
                        viewId={props.view}
                        query={queryDateRange}
                    />                 
                </div>
            </div>`
        </>
    )
}

export const EpdPageViewsPerPathChart = (props) => {

    const container = props.view+'_'+divCounter++;

    return (
        <>
            <div style={{ marginTop: "30px" }}>
                <div id={container}>
                    <PageViewsPerPathChart
                        container={container}
                        options={{width: 290, height:250}}
                        gapi={props.gapi}
                        viewId={props.view}
                        query={queryDateRange}
                    />                 
                </div>
            </div>`
        </>
    )
}


export const EpdSessionsGeoChart = (props) => {

    const container = props.view+'_'+divCounter++;

    return (
        <>
            <div style={{ marginTop: "30px" }}>
                <div id={container}>
                    <SessionsGeoChart
                        container={container}
                        options={{width: 290, height:250}}
                        gapi={props.gapi}
                        viewId={props.view}
                        query={queryDateRange}
                    />                 
                </div>
            </div>
        </>
    )
}






export const EpdSessionsByDateChart = (props) => {
        
    const container = props.view+'_'+divCounter++;

    return (
        <>
            <div style={{ marginTop: "30px" }}>
                <div id={container}>
                    <SessionsByDateChart
                        container={container}
                        options={{width: 290, height:250}}
                        gapi={props.gapi}
                        viewId={props.view}
                        query={queryDateRange}
                    />                  
                </div>
            </div>`
        </>
    )
}


 export const EpdSessionsByDateChartEnhanced = (props) => {
        
    const queryDateRangeEnhanced=
    {
        // 'start-date':
        'end-date': 'yesterday',
        dimensions: 'ga:date',
    }

    const optionsDateRangeEnhanced= {
        // title: 
        width: 290, 
        height:250
    }

    const days  = (props.days       === undefined) ? '28' : props.days;
    const title = (props.chartTitle === undefined) ? 'Sess/Page/User' : props.chartTitle;

    switch (title) {
        case 'Sessions':
            queryDateRangeEnhanced['metrics'] = 'ga:sessions';
            break;
        case 'Pages':
            queryDateRangeEnhanced['metrics'] = 'ga:pageviews';
            break;
        case 'Users':
            queryDateRangeEnhanced['metrics'] = 'ga:users';
            break;
        default: // 'Sess/Page/User'
             queryDateRangeEnhanced['metrics'] = 'ga:sessions,ga:pageviews,ga:users';
    }

    queryDateRangeEnhanced['start-date'] = days+'daysAgo';
    optionsDateRangeEnhanced['title'] = title+' ('+days+')';

    const container = props.view+'_'+divCounter++;
    

    return (
        <>
            <div style={{ marginTop: "30px" }}>
                <div id={container}>
                    <SessionsByDateChart
                        container={container}
                        gapi={props.gapi}
                        viewId={props.view}
                        query={queryDateRangeEnhanced}
                        options={optionsDateRangeEnhanced}
                    />                  
                </div>
            </div>`
        </>
    )
}


// dalays the display of a chart card by a delay value
export const WebAnalytics = (props) => {

    const newView = (props.view !== undefined) ? props.view : "";

    const [lifecycle, setLifecycle] = React.useState('initial'); // initial --> loading --> loaded
    const [view, setView] = React.useState(newView); 

    if (view !== newView) // then view changed
    {
        setView(newView);
        setLifecycle('initial');
    }

    const cardinfo = "d-flex justify-content-center"; //justify-center
    const url = "https://"+props.url;
    const delay = 500*parseInt(props.delay); // counter * number of millisecs
  


    // all the lifecycle management here is to all for a delay in calling google analyticss (i.e. not all at once)
    if ((lifecycle === 'initial') && (view !== ""))  // new item with a  view
    {
        if (delay === 0) {
          setLifecycle('loaded');
        }
        else {
          setLifecycle('loading');
        }
    }


    useEffect(() => {
      if (lifecycle === 'loading')
      {
        let timer = setTimeout(() => setLifecycle('loaded'), delay);

        return () => clearTimeout(timer);
      }
    }, [lifecycle]);

                 
  return (
      <>
        <Card style={{width: 300}}>
          <CardHeaderLoading loading={lifecycle === 'loading'} title={<a href={url}>{props.url}</a>}/>
          <Card.Body className={cardinfo} style={{width: 300, height: 300}}>
               { (lifecycle === 'loaded') && (props.view.length > 0) && <props.Component  {...props} /> }  
            </Card.Body>
         </Card>
      </>
    )
};



// this component is complex because it integrates with google analytics see https://www.npmjs.com/package/react-use-analytics-api
export const AnalyticsStart = (props) => {

    const { ready, gapi, authorized, error } = useAnalyticsApi();
    const authorize = useAuthorize(gapi, {  // serverAuth: {access_token: props.user.jwtService},
      clientId: props.user.clientId,
      container: "authorize-container-id",
    });
    const signOut = useSignOut(gapi);
  
    // Workaround for API limitation
    const [authorizeCalled, setAuthorizeCalled] = React.useState(false);
    const authDiv = React.useRef(null);
    //const hasAuthElements =
    //  authDiv.current && authDiv?.current?.children?.length > 0;
  
    React.useEffect(() => {

      if (ready && !error && !authorizeCalled) {
        authorize();
        setAuthorizeCalled(true);
      }
    }, [authorize, authorizeCalled, error, ready]);
  

    React.useEffect(() => {
      if (authorized) {
        props.setGapi(gapi);
      }
    }, [authorized]);

    //const cardinfo = "d-flex justify-content-center"; //justify-center

    const textStyle = { 
      textAlign: "center"
    };
  
    if (error) {
      return (<>{error.toString()}</>)
    }
    else {
      if (ready && !!gapi) {
        if (authorized) {
            
            return (
                <Container fluid="lg" className="content_container">
                    {/*☀️ Logged into Google Analytics!{" "} */} 
                    <Row>
                        <Col>
                            <Button style={{marginBottom: 10, marginRight: 10}} variant="warning" onClick={() => {signOut(); window.location.href = "/"}}>Sign Out of Analytics</Button>
                            If no charts appear then sign out of Analytics
                        </Col>
                    </Row>
                </Container>
            )
        } else {
          return (
            <Container fluid="lg" className="content_container">
              {/*🔐 Not logged into Google Analytics */}
              <Row>
                <Col>
                    <div id="authorize-container-id" ref={authDiv} />
                </Col>
              </Row>
            </Container>
          )
        }
      } else {
        return (
            <Container fluid="lg" className="content_container">
                <Row>
                    <Col>
                            <div style={textStyle}><Spinner variant="warning" animation="border" /></div>
                    </Col>
                </Row>
            </Container>
        )
      }
    } 
  }



//export {mergeToData, AnalyticsStart, WebAnalytics, EpdSessionsByDateChart};
