
import React from 'react';

import { Container, Row, Col, Spinner, Button } from 'react-bootstrap';


import Expansion from './Expansion';

import {HostingFirstStageContent} from './Epdhosting';
import {BackupFirstStageContent} from './Epdbackupstatus';
import {UptimeFirstStageContent} from './Epduptime';

const SummaryFirstStageContent = (obj) => {
   // const container = {}

    const Hosting = HostingFirstStageContent(obj);
    const Backup  = BackupFirstStageContent(obj);
    const Monitor = UptimeFirstStageContent(obj);

    const container = {...Hosting, ...Backup, ...Monitor};

    return (container);
}




class StatusInformation extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {expand: [false, false, false] };
        
        this.expand = this.expand.bind(this);
    }


    expand = (index) => {
        const newState = this.state.expand;

        if (newState[index]) {
            // here if index is currently expanded
            newState[index] = false; 
        } else {
            // here if index item is currently not expanded
            newState.forEach((element, index) => newState[index] = false); 
            newState[index] = true; 
        } 

        this.setState({expand: newState});
    }
     


    render() {

        if (this.props.data.length === 0) { // if no data then do nothing
            return null
        }

        const expand = this.state.expand;


        const upIndex = 2;
        const baIndex = 1;
        const hoIndex = 0;

        const upState = expand[upIndex];
        const baState = expand[baIndex];
        const hoState = expand[hoIndex];

        const upCols = upState ? 4 : 1;
        const baCols = baState ? 4 : 2;
        const hoCols = hoState ? 3 : 1;

        //console.log(this.props.data)

        const firststage = this.props.data.map((obj) => {
            return SummaryFirstStageContent(obj);
        })


        const listing = firststage.map((obj) => (
            <tr className="SummaryStatusRow" key={obj.domain}>
                <td className="number">{obj.Item}</td>
                <td className="domain"><a href={obj.Url}>{obj.domain}</a></td>
                
                <td className={obj.TDstatus}>{obj.status}</td>
                {upState && <td className={obj.TDuptimes[0]}>{(obj.status !== "") && parseFloat(obj.uptimes[0]).toFixed(2)}</td>}
                {upState && <td className={obj.TDuptimes[1]}>{(obj.status !== "") && parseFloat(obj.uptimes[1]).toFixed(2)}</td>}
                {upState && <td className={obj.TDuptimes[2]}>{(obj.status !== "") && parseFloat(obj.uptimes[2]).toFixed(2)}</td>}

                <td className={obj.TDnotziplmDays}>{obj.notzipLastModified}</td>
                <td className={obj.TDziplmDays}>{obj.zipLastModified}</td>
                {baState && <td className={obj.TDnotzipfmDays}>{obj.notzipFirstModified}</td>}
                {baState && <td className={obj.TDzipfmDays}>{obj.zipFirstModified}</td>}

               
                <td className={obj.TDsuspended}>{obj.suspended}</td>
                {hoState && <td className={obj.TDdiskpercent}>{obj.diskpercent}</td>}
                {hoState && <td className={obj.TDbandpercent}>{obj.bandpercent}</td>}

            </tr>
        ))



        const output = [
            <table className="SummaryStatusTable" key="SummaryStatusTable">
                <tbody>
                    <tr>
                        <th className="SummaryStatusTitleBlank"></th>
                        <th className="SummaryStatusTitleBlank"></th>
                        <th className="SummaryStatusTitle" colSpan={upCols}><Expansion index={upIndex} title={"Uptime"} states={this.state.expand} toggle={this.expand}/></th>
                        <th className="SummaryStatusTitle" colSpan={baCols}><Expansion index={baIndex} title={"Backups"} states={this.state.expand} toggle={this.expand}/></th>
                        <th className="SummaryStatusTitle" colSpan={hoCols}><Expansion index={hoIndex} title={"Hosting"} states={this.state.expand} toggle={this.expand}/></th>
                    </tr>
                    <tr>
                        <th className="SummaryStatusTitleBlank"></th>
                        <th className="SummaryStatusTitleBlank"></th>

                        <th className="SummaryStatusTitle">Status</th>
                        {upState && <th className="SummaryStatusTitleBlank" colSpan="3">Up % (for no. Days)</th>}

                        <th className="SummaryStatusTitle" colSpan="2">Latest</th>
                        {baState && <th className="SummaryStatusTitle" colSpan="2">Oldest</th>}

                        <th className="SummaryStatusTitle">Status</th>
                        {hoState && <th className="SummaryStatusTitle">Disk</th>}
                        {hoState && <th className="SummaryStatusTitle">BW</th>}
                    </tr>
                    <tr>
                        <th className="SummaryStatusTitle">Item</th>
                        <th className="SummaryStatusTitle">Site</th>

                        <th className="SummaryStatusTitle">Now</th>
                        {upState && <th className="SummaryStatusTitle">1</th>}
                        {upState && <th className="SummaryStatusTitle">7</th>}
                        {upState && <th className="SummaryStatusTitle">30</th>}

                        <th className="SummaryStatusTitle">MySQL</th>
                        <th className="SummaryStatusTitle">Others</th>
                        {baState && <th className="SummaryStatusTitle">MySQL</th>}
                        {baState && <th className="SummaryStatusTitle">Others</th>}

                        <th className="SummaryStatusTitle">Now</th>
                        {hoState && <th className="SummaryStatusTitle">%</th>}
                        {hoState && <th className="SummaryStatusTitle">%</th>}
                    </tr >
                    {listing}
                </tbody>
            </table>
        ]


        return (
            <>
                {output}
            </>
        )
    }

}



class Epdsummary extends React.Component {
    constructor() {
        super();
    }



    render() { 

        console.log('Epdsummary', this.props.state.data);

        const textStyle = {
            textAlign: "center"
        };
    
        if (this.props.state.data.length === 0) {

            return (

                <Container fluid="lg">
                    <Row>
                        <Col>
                            <h1 style={textStyle}>Summary</h1>
                            <div style={textStyle}><Spinner  animation="border" /></div>
                        </Col>
                    </Row>
                </Container>
            );

        }
        else {

            return (
                <Container fluid="lg">
                    <Row>
                        <Col>
                             <h1 style={textStyle}>Summary</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <StatusInformation data={this.props.state.data} />
                        </Col>
                    </Row>
                </Container>

            );
        }
    }
}

export default Epdsummary


