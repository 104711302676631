
import React from 'react';


import {ApiGet} from './Api';

//import { Container, Row, Col } from 'bootstrap-4-react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';


import {RAGuptime, pad, timedateConvert, daysOld, byteToMegabyte, secondstotime} from './Library';
import {UptimeMergeToData} from './Library';

// import Spinner from 'react-bootstrap/Spinner';




const UptimeFirstStageContent = (obj) => {
    const container = {}

    container.Item = obj.index;
    container.domain = obj.domain

    container.Url = 'https://www.'+ obj.domain;

    if (!obj.hasOwnProperty('monitor')) {

        container.status = "";

        const emptyTimes = ["", "", ""];

        container.uptimes       = emptyTimes;
        container.TDuptimes     = emptyTimes;
        container.durations     = emptyTimes;
        container.TDdurations   = emptyTimes;


    } else {

        switch (obj.monitor.status) {
            case 0:  container.status = "paused";           container.TDstatus= 'Amber'; break;
            case 1:  container.status = "not checked yet";  container.TDstatus= 'Amber'; break;
            case 2:  container.status = "up";               container.TDstatus= 'Blue';  break;
            case 8:  container.status = "seems down";       container.TDstatus= 'Red';   break;
            case 9:  container.status = "down";             container.TDstatus= 'Red';   break;
            default: container.status = obj.montor.status;  container.TDstatus= 'Red';
        }

        container.uptimes = obj.monitor.custom_uptime_ratio.split('-');
        container.TDuptimes = container.uptimes.map((item) => {
            return RAGuptime(item);
        });

        container.durations = obj.monitor.custom_down_durations.split('-');
        container.durations = container.durations.map((item) => {
        // return (item == 0) ? "" : (item/(60*60)).toFixed(2);
            return (item == 0) ? "" : secondstotime(item);
        });
        container.TDdurations = container.TDuptimes;  
    }

 /* 

    container.TDnotziplmDays = 'notziplmDays day' + daysOld(obj.notzipLastModified);
    container.notzipLastModified = timedateConvert(obj.notzipLastModified);

    container.TDziplmDays = 'ziplmDays day' + daysOld(obj.zipLastModified);
    container.zipLastModified = timedateConvert(obj.zipLastModified);

    container.TDfmDays = 'fmDays day' + daysOld(obj.FirstModified);
    container.FirstModified = timedateConvert(obj.FirstModified);

    container.TDzipfmDays = 'zipfmDays day' + daysOld(obj.zipFirstModified);
    container.zipFirstModified = timedateConvert(obj.zipFirstModified);

    container.TDnotzipfmDays = 'notzipfmDays day' + daysOld(obj.notzipFirstModified);
    container.notzipFirstModified = timedateConvert(obj.notzipFirstModified);

    container.zipFileCount = obj.zipFileCount;
    container.notzipFileCount = obj.notzipFileCount;
    container.FileCount = obj.FileCount;

    container.Size = byteToMegabyte(obj.Size);
*/
    return (container);
}


// const Status = ({ data }) => {
class MonitorInformation extends React.Component {

    //const function timedateConvert(timestamp) {

    //console.log(data);
    render() {

        if (this.props.data.length === 0) { // if no data then do nothing
            return null
        }

        //console.log(this.props.data);

        //let item = 0;
        const newdata = this.props.data;
        const firststage = newdata.map((obj) => {
 //           obj.Item = ++item;
            return UptimeFirstStageContent(obj);
        })


        const listing = firststage.map((obj) => (
            <tr className="UptimeStatusRow" key={obj.domain}>
                <td className="number">{obj.Item}</td>
                <td className="domain"><a href={obj.Url}>{obj.domain}</a></td>
                
                <td className={obj.TDstatus}>{obj.status}</td>


                <td className={obj.TDuptimes[0]}>{(obj.status !== "") && parseFloat(obj.uptimes[0]).toFixed(2)}</td>  
                <td className={obj.TDdurations[0]}>{obj.durations[0]}</td>

                <td className={obj.TDuptimes[1]}>{(obj.status !== "") && parseFloat(obj.uptimes[1]).toFixed(2)}</td>
                <td className={obj.TDdurations[1]}>{obj.durations[1]}</td>

                <td className={obj.TDuptimes[2]}>{(obj.status !== "") && parseFloat(obj.uptimes[2]).toFixed(2)}</td>
                <td className={obj.TDdurations[2]}>{obj.durations[2]}</td>
            </tr>
        ))



        const output = [
            <table className="UptimeStatusTable" key="UptimeStatusTable">
                <tbody>
                    <tr>
                        <th className="UptimeStatusTitleBlank"></th>
                        <th className="UptimeStatusTitleBlank"></th>
                        <th className="UptimeStatusTitleBlank"></th>
                        <th className="UptimeStatusTitle" colSpan="2">1 Day</th>
                        <th className="UptimeStatusTitle" colSpan="2">7 Days</th>
                        <th className="UptimeStatusTitle" colSpan="2">30 Days</th>
                    </tr>
                    <tr>
                        <th className="UptimeStatusTitle">Item</th>
                        <th className="UptimeStatusTitle">Site</th>

                        <th className="UptimeStatusTitle">Status</th>

                        <th className="UptimeStatusTitle">% Up</th>
                        <th className="UptimeStatusTitle">Time&#8595;</th>


                        <th className="UptimeStatusTitle">% Up</th>
                        <th className="UptimeStatusTitle">Time&#8595;</th>
                        
                        <th className="UptimeStatusTitle">% Up</th>
                        <th className="UptimeStatusTitle">Time&#8595;</th>
                    </tr >
                    {listing}
                </tbody>
            </table>
        ]

 


        return (
            <>
                {output}
            </>
        )
    }

}



 /*
    // takes the google list of accounts and extracts the profile to create a view which is merged into the (state) data array

    // create an array of accounts each with a view id
    const accounts = [];
    items.forEach((account) => {
      account.webProperties.forEach((property) => {
        property.profiles.forEach((profile) => {
          // standarise the layout of the url (remove https and www)
          var url = property.websiteUrl.replace('https:\/\/www.', 'https:\/\/').toLowerCase(); 
          url = url.replace('https:\/\/', '');
          // save new record
          accounts.push({account: account.name, accountId: account.id, url: url, profileId: profile.id});
        })
      })
    })
    // create view records in 'data' with the profileIds from the google account list
    const newdata = data; // work with a local copy of the list of 'domaines' 
    accounts.forEach((account) => {
      newdata.find((d) => { 
        if (account.url === d.domain) {d.view = 'ga:'+account.profileId; return true} else {return false}; // add extra view field
      });
    });
    setState({data: newdata}); // update the data array with view records   
    */



class Epduptime extends React.Component {
    constructor() {
        super();
        //this.state = { data: [] };

        //this.setState = this.setState.bind(this);
    }


/*
    componentDidMount() {
        Api('google')                                                    // gets the user information 
          .then(res => { 
            this.setState({ user: res });
            Api('sites')                                                       // gets a list of sites
              .then(res => {console.log(res); this.setState({ data: res });})  
          })
      }
      setGapi(gapi) {
        this.setState({gapi: gapi});
        const res = gapi.client.analytics.management.accountSummaries.list()         // gets a list of analytics accounts
          .then(res => mergeToData(res.result.items, this.state.data, this.setState) // merges the items into the data array
          )
      }
*/
/*
    componentDidMount() {
        ApiGet('domain')
            .then(res => {
                const domains = res.Items;
                ApiGet('uptime') 
                    .then(res => {
                        this.setState({data: UptimeMergeToData(res.monitors, domains)}) // merges the monitors into the sites array
                    })
                    .catch(err => console.log('Api Epduptime uptime', err));       
            })
            .catch(err => console.log('Api Epduptime sites', err));
    }   
  */          
  /*   
    componentDidMount() {
        Api('uptime') 
            .then(res => this.setState({ data: res }))
            .catch(err => console.log('Api Epduptime', err));      
    }
    
 */
    render() { 


        console.log('UPTIME', this.props.state.data);

        const textStyle = {
            textAlign: "center"
        };
    

        if (this.props.state.data.length === 0) {

            return (

                <Container fluid="lg">
                    <Row>
                        <Col>
                            <h1 style={textStyle}>Uptime</h1>
                            <div style={textStyle}><Spinner  animation="border" /></div>
                        </Col>
                    </Row>
                </Container>
            );

        }
        else {

            return (
                <Container fluid="lg">
                    <Row>
                        <Col>
                             <h1 style={textStyle}>Up Time</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                           <MonitorInformation data={this.props.state.data} />
                        </Col>
                    </Row>
                </Container>

            );
        }
    }
}

export {Epduptime, UptimeFirstStageContent };


