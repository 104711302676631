
import React from 'react';


import {ApiGet} from './Api';

//import { Container, Row, Col } from 'bootstrap-4-react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';

import {penceToPounds, RAGhostingPercent, RAGhostingDisk, HostingMergeToData, pad, timedateConvert, daysOld, byteToMegabyte, secondstotime} from './Library';

import Expansion from './Expansion';






const InformationFirstStageContent = (obj) => {
    //console.log('object',obj)

    let container = {}

    container.Item = obj.index;
    container.domain = obj.domain
//            container.domain = '<a href="https://www.' + obj.domain + '">' + obj.domain + '</a>';
    container.Url = 'https://www.'+ obj.domain;

    container.status = "";
    container.TDstatus = "";

    container.hostingMonth = "";
    container.maintenanceMonth = "";
    container.curationMonth = "";
    container.hostingAnnual = "";
    container.maintenanceAnnual = "";
    container.curationAnnual = "";
    container.totalAnnual = "";
    container.totalMonth = "";
    container.comments = "";

    container.TDpricing= "";

  
    //container.TDcomments = "";

    if (obj.hasOwnProperty('information')) {
        container = {...container, ...obj.information}
 /*   

        container.totalAnnual = (+container.hostingAnnual +
                                 +container.maintenanceAnnual +
                                 +container.curationAnnual) + 
                                (+container.hostingMonth +
                                 +container.maintenanceMonth +
                                 +container.curationMonth) * 12
*/

        container.totalAnnual =    (Number(container.hostingAnnual) +
                                    Number(container.maintenanceAnnual) +
                                    Number(container.curationAnnual)) + 

                                    (Number(container.hostingMonth) +
                                    Number(container.maintenanceMonth) +
                                    Number(container.curationMonth)) * 12

        container.totalMonth =      Number(container.totalAnnual) / 12;
        
        switch (obj.information.status) {
                case 'active':  container.TDstatus = "Blue"; 
                                container.TDpricing = "Blue";
                                break;

                case 'free':    container.TDstatus = "Green"; 
                                container.TDpricing = "Green";
                                break;

                case 'pending': container.TDstatus = "Amber"; 
                                container.TDpricing = "Amber";
                                break;
                default:
                        
        }

    }
    return (container);
}


// const Status = ({ data }) => {
class StatusInformation extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {expand: [false] };
        
        this.expand = this.expand.bind(this);
    }


    expand = (index) => {
        const newState = this.state.expand;

        if (newState[index]) {
            // here if index is currently expanded
            newState[index] = false; 
        } else {
            // here if index item is currently not expanded
            newState.forEach((element, index) => newState[index] = false); 
            newState[index] = true; 
        } 

        this.setState({expand: newState});
    }

    //const function timedateConvert(timestamp) {

    //console.log(data);
    render() {

        if (this.props.data.length === 0) { // if no data then do nothing
            return null
        }
        const expand = this.state.expand;

        const toIndex = 0;

        const toState = expand[toIndex];

        const toCols = 2; //toState ? 8 : 2;
        //console.log(this.props.data);

        const firststage = this.props.data.map((obj) => {
            return InformationFirstStageContent(obj);
        })


       //{firststage.filter(({status}) => status === 'active').reduce((a, b) => +a.totalMonth + +b.totalMonth)}

       //const filter =  firststage.filter(({status}) => status === 'active');
       //const map = filter.map(i => i.totalMonth);
       //const reduce =  map.reduce((a, b) => +a + +b);

        //ole.log('filter------------------', filter, map, reduce, firststage.filter(({status}) => status === 'active').map(i => i.totalMonth).reduce((a, b) => +a + +b)); 
       
        const listing = firststage.map((obj) => (
            <tr className="HostingStatusRow" key={obj.domain}>
                <td className="number">{obj.Item}</td>
                <td className="domain"><a href={obj.Url}>{obj.domain}</a></td>

                <td className={obj.TDstatus}>{obj.status}</td>

                {toState && <td className={obj.TDpricing}>{penceToPounds(obj.hostingMonth)}</td>}
                {toState && <td className={obj.TDpricing}>{penceToPounds(obj.maintenanceMonth)}</td>}
                {toState && <td className={obj.TDpricing}>{penceToPounds(obj.curationMonth)}</td>}
                {toState && <td className={obj.TDpricing}>{penceToPounds(obj.hostingAnnual)}</td>}                
                {toState && <td className={obj.TDpricing}>{penceToPounds(obj.maintenanceAnnual)}</td>}
                {toState && <td className={obj.TDpricing}>{penceToPounds(obj.curationAnnual)}</td>}
                
                <td className={obj.TDpricing}>{penceToPounds(obj.totalMonth)}</td>
                <td className={obj.TDpricing}>{penceToPounds(obj.totalAnnual)}</td>                
                <td className={obj.TDpricing}>{obj.comments}</td>      

            </tr>
        ))

       //const summary = (prev, current) => parseInt(prev.diskused, 10) + parseInt(current.diskused, 10);

       //console.log('summary', firststage.reduce(summary));

       //console.log('firststage', firststage.reduce((a, b) => +a.diskused + +b.diskused),  firststage.map(i => i.diskused).reduce((a, b) => +a + +b));
       
       
       ///firststage.map(i=>i.diskused).reduce((a,b)=>parseInt(a)+parseInt(b)));
    
       const sumWithFilter = (source, matchStatus, field) => {
            return penceToPounds(source.filter(({status}) => status === matchStatus).map(i => i[field]).reduce((a, b) => +a + +b, 0));
       }

       const totals =   [
                            <tr className="HostingStatusRow" key="total">
                                <td className="HostingStatusTotalBlank"></td>
                                <td className="HostingStatusTotalTitle">TOTAL</td>
                                <td className="HostingStatusTotalBlank"></td>

                                {toState && <td className="HostingStatusTotalBlank"></td>}
                                {toState && <td className="HostingStatusTotalBlank"></td>}
                                {toState && <td className="HostingStatusTotalBlank"></td>}
                                {toState && <td className="HostingStatusTotalBlank"></td>}
                                {toState && <td className="HostingStatusTotalBlank"></td>}
                                {toState && <td className="HostingStatusTotalBlank"></td>}
                                
                                <td className="HostingStatusTotalBlue">{sumWithFilter(firststage, 'active', 'totalMonth')}</td>
                                <td className="HostingStatusTotalBlue">{sumWithFilter(firststage, 'active', 'totalAnnual')}</td>
                                <td className="HostingStatusTotalBlank"></td>
                            </tr>
                        ];

        const output = [
            <table className="HostingStatusTable" key="HostingStatusTable">
                <tbody>
                    <tr>
                        <th className="HostingStatusTitleBlank"></th>
                        <th className="HostingStatusTitleBlank"></th>
                        <th className="HostingStatusTitleBlank"></th>
                        {toState && <th className="HostingStatusTitle" colSpan="3">£ Monthly</th>}
                        {toState && <th className="HostingStatusTitle" colSpan="3">£ Annually</th>}
                        <th className="HostingStatusTitle" colSpan={toCols}><Expansion index={toIndex} title={"£ Totals"} states={this.state.expand} toggle={this.expand}/></th>
                        <th className="HostingStatusTitleBlank"></th>
                    </tr>
                    <tr>
                        <th className="HostingStatusTitle">Item</th>
                        <th className="HostingStatusTitle">Domain</th>
                        <th className="HostingStatusTitle">Status</th>
                     
                        {toState && <th className="HostingStatusTitle">Host</th>}
                        {toState && <th className="HostingStatusTitle">Maint.</th>}
                        {toState && <th className="HostingStatusTitle">Cur.</th>}
                        {toState && <th className="HostingStatusTitle">Host</th>}
                        {toState && <th className="HostingStatusTitle">Maint.</th>}
                        {toState && <th className="HostingStatusTitle">Cur.</th>}
                        
                        <th className="HostingStatusTitle">Month</th>
                        <th className="HostingStatusTitle">Year</th>

                        <th className="HostingStatusTitle">Comments</th>
                    </tr>
                    {listing}
                    {totals}
                </tbody>
            </table>
        ]

 


        return (
            <>
                {output}
            </>
        )
    }

}



class Epdinformation extends React.Component {
    constructor() {
        super();
        //this.state = { data: [] };
    }




/*
    componentDidMount() {
        ApiGet('domain') 
        .then(res => {
            const domains = res.Items;
            ApiGet('hosting') 
            .then(res => {
                const newdata = HostingMergeToData(res.listaccts.data.acct, res.showbw.data.acct, domains);
                this.setState({ data: newdata });
            });
        });
    }
    
*/ 
    render() { 

        console.log('Epdhosting', this.props.state.data);

        const textStyle = {
            textAlign: "center"
        };
    
        if (this.props.state.data.length === 0) {

            return (

                <Container fluid="lg">
                    <Row>
                        <Col>
                            <h1 style={textStyle}>Information</h1>
                            <div style={textStyle}><Spinner  animation="border" /></div>
                        </Col>
                    </Row>
                </Container>
            );

        }
        else {

            return (
                <Container fluid="lg">
                    <Row>
                        <Col>
                             <h1 style={textStyle}>Information</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <StatusInformation data={this.props.state.data} />
                        </Col>
                    </Row>
                </Container>

            );
        }
    }
}

export {Epdinformation, InformationFirstStageContent };


