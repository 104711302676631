
import React from 'react';


import {ApiGet} from './Api';

//import { Container, Row, Col } from 'bootstrap-4-react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';

import {pad, timedateConvert, daysOld, byteToMegabyte, secondstotime} from './Library';

import {BackupMergeToData} from './Library';
// import Spinner from 'react-bootstrap/Spinner';






const BackupFirstStageContent = (obj) => {
    //console.log('object',obj)

    const container = {}

    container.Item = obj.index;
    container.domain = obj.domain
//            container.domain = '<a href="https://www.' + obj.domain + '">' + obj.domain + '</a>';
    container.Url = 'https://www.'+ obj.domain;

    if (!obj.hasOwnProperty('backup')) {

        container.TDnotziplmDays = "";
        container.notzipLastModified = "";

        container.TDziplmDays = "";
        container.zipLastModified = "";

        container.TDzipfmDays = "";
        container.zipFirstModified = "";

        container.TDnotzipfmDays = "";
        container.notzipFirstModified = "";

        container.zipFileCount = "";
        container.notzipFileCount = "";
        container.FileCount = "";

        container.Size= "";
    }
    else {

        container.TDnotziplmDays = 'notziplmDays day' + daysOld(obj.backup.notzipLastModified);
        container.notzipLastModified = timedateConvert(obj.backup.notzipLastModified);

        container.TDziplmDays = 'ziplmDays day' + daysOld(obj.backup.zipLastModified);
        container.zipLastModified = timedateConvert(obj.backup.zipLastModified);

        //container.TDfmDays = 'fmDays day' + daysOld(obj.FirstModified);
        //container.FirstModified = timedateConvert(obj.FirstModified);

        container.TDzipfmDays = 'zipfmDays day' + daysOld(obj.backup.zipFirstModified);
        container.zipFirstModified = timedateConvert(obj.backup.zipFirstModified);

        container.TDnotzipfmDays = 'notzipfmDays day' + daysOld(obj.backup.notzipFirstModified);
        container.notzipFirstModified = timedateConvert(obj.backup.notzipFirstModified);

        container.zipFileCount = obj.backup.zipFileCount;
        container.notzipFileCount = obj.backup.notzipFileCount;
        container.FileCount = obj.backup.FileCount;

        container.Size = byteToMegabyte(obj.backup.Size);
    
    }

    return (container);
}


// const Status = ({ data }) => {
class StatusInformation extends React.Component {

    //const function timedateConvert(timestamp) {

    //console.log(data);
    render() {

        if (this.props.data.length === 0) { // if no data then do nothing
            return null
        }

        //console.log(this.props.data);

        const firststage = this.props.data.map((obj) => {
            return BackupFirstStageContent(obj);
        })


        const listing = firststage.map((obj) => (
            <tr className="BackupStatusRow" key={obj.domain}>
                <td className="number">{obj.Item}</td>
                <td className="domain"><a href={obj.Url}>{obj.domain}</a></td>

                <td className={obj.TDnotziplmDays}>{obj.notzipLastModified}</td>
                <td className={obj.TDziplmDays}>{obj.zipLastModified}</td>

                <td className={obj.TDnotzipfmDays}>{obj.notzipFirstModified}</td>
                <td className={obj.TDzipfmDays}>{obj.zipFirstModified}</td>
                {/*<td className={obj.TDfmDays}>{obj.FirstModified}</td>*/}

                <td className="number">{obj.notzipFileCount}</td>
                <td className="number">{obj.zipFileCount}</td>
                {/*<td className="number">{obj.FileCount}</td>*/}

                <td className="number">{obj.Size}</td>
            </tr>
        ))



        const output = [
            <table className="BackupStatusTable" key="BackupStatusTable">
                <tbody>
                    <tr>
                        <th className="BackupStatusTitleBlank"></th>
                        <th className="BackupStatusTitleBlank"></th>
                        <th className="BackupStatusTitle" colSpan="2">Latest</th>
                        <th className="BackupStatusTitle" colSpan="2">Oldest</th>
                        <th className="BackupStatusTitle" colSpan="3">Total</th>
                    </tr>
                    <tr>
                        <th className="BackupStatusTitle">Item</th>
                        <th className="BackupStatusTitle">Site</th>

                        <th className="BackupStatusTitle">MySQL</th>
                        <th className="BackupStatusTitle">Others</th>

                        <th className="BackupStatusTitle">MySQL</th>
                        <th className="BackupStatusTitle">Others</th>
                        {/*<th className="BackupStatusTitle">Oldest File</th>*/}


                        <th className="BackupStatusTitle">MySQL</th>
                        <th className="BackupStatusTitle">Others</th>
                        {/*<th className="BackupStatusTitle">Files</th>*/}

                        <th className="BackupStatusTitle">Size (MB)</th>
                    </tr >
                    {listing}
                </tbody>
            </table>
        ]

 


        return (
            <>
                {output}
            </>
        )
    }

}



class Epdbackupstatus extends React.Component {
    constructor() {
        super();
        //this.state = { data: [] };
    }




/*
    componentDidMount() {
        ApiGet('domain') 
        .then(res => {
            const domains = res.Items;
            ApiGet('backup') 
            .then(res => {
                const newdata = BackupMergeToData(res, domains);
                this.setState({ data: newdata });
            });
        });
    }
    
 */


    render() { 

        console.log('Epdbackupstatus', this.props.state.data);

        const textStyle = {
            textAlign: "center"
        };
    
        if (this.props.state.data.length === 0) {

            return (

                <Container fluid="lg">
                    <Row>
                        <Col>
                            <h1 style={textStyle}>Backup Status</h1>
                            <div style={textStyle}><Spinner  animation="border" /></div>
                        </Col>
                    </Row>
                </Container>
            );

        }
        else {

            return (
                <Container fluid="lg">
                    <Row>
                        <Col>
                             <h1 style={textStyle}>Backup Status</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <StatusInformation data={this.props.state.data} />
                        </Col>
                    </Row>
                </Container>

            );
        }
    }
}

export {Epdbackupstatus, BackupFirstStageContent };


