// pages/Home.js
import './App.css';
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button, Spinner} from 'react-bootstrap';
import { useAnalyticsApi,useAuthorize,useSignOut} from "react-use-analytics-api";
import { AnalyticsDashboard, SessionsByDateChart, SessionsGeoChart, SessionsBySourceChart, SessionsByHourChart, PageViewsPerPathChart,   AuthorizeButton, SignOutButton, ViewSelector, } from "react-analytics-charts";
// Over ten different commonly used charts are available
import {ApiGet} from './Api';
import { CardHeaderLoading } from './CardServices';
import {AnalyticsStart, WebAnalytics, EpdSessionsByDateChart,EpdSessionsByDateChartEnhanced} from './GoogleServices';
 
import {AnalyticsMergeToData} from './Library';



const AnalyticsList = (props) => {

  
  const cardinfo = "d-flex justify-content-center"; //justify-center

  var delay = 0; // delay used to stop google rejecting messages passed to WebAnalytics
  
  //console.log('AnalyticsList', props.data);

  const sites = props.data.map((record) => {
    const view = record.hasOwnProperty('analytic') ? record.analytic.view : "";
    const output = [<div key={record.domain}><WebAnalytics Component={EpdSessionsByDateChartEnhanced} url={record.domain} gapi={props.gapi} view={view} delay={delay}/></div>];

    if (view !== "") { delay++; }
    
    return(output);
  })

  return (
    <>
      <Row className={cardinfo}> 
        {sites}
      </Row>
    </>
  )
  
};





class Epdanalytics extends React.Component {
  constructor() {
      super();
      //this.state = { user: [], data: [], domains: [], gapi: null };
      this.state = { analyticsMerged: false, localData: [], gapi: null }; // use localcopy of data because this module changes it
      this.setGapi = this.setGapi.bind(this);
      this.setState = this.setState.bind(this);
  }

/*
  componentDidMount() {
    ApiGet('google')                                                    // gets the user information 
      .then(res => { 
        this.setState({ user: res });
        ApiGet('domain')                                                       // gets a list of sites
          .then(res => {this.setState({ domains: res.Items });})  
      })
  }
*/

/*
  // called by a child when gapi is known
  setGapi(gapi) {
    this.setState({gapi: gapi});
    const res = gapi.client.analytics.management.accountSummaries.list()         // gets a list of analytics accounts
      //.then(res => AnalyticsMergeToData(res.result.items, this.state.data, this.setState) // merges the items into the data array
      .then(res => this.setState({data: AnalyticsMergeToData(res.result.items, this.state.domains)}) // merges the items into the data array
    );
  }
*/
  // called by a child when gapi is known
  setGapi(gapi) {
    const newGapi = gapi;
    this.setState({gapi: gapi});
    const res = gapi.client.analytics.management.accountSummaries.list()         // gets a list of analytics accounts
      //.then(res => this.setState({data: AnalyticsMergeToData(res.result.items, this.state.domains)}) // merges the items into the data array
      .then(res => {
        this.setState({gapi: newGapi, analyticsMerged: true, localData: AnalyticsMergeToData(res.result.items, this.props.state.data)}); // merges the items into the data array
      })
  }

  render () {

    const textStyle = { 
      textAlign: "center"
    };
  

    console.log('analytics..........',this.state, this.props.state);

    if (this.props.state.user.length === 0) { 

      return (
          <Container fluid="lg" className="content_container">
            <Row>
              <Col>
                <h1 style={textStyle}>Analytics</h1>
                <div  style={textStyle}><Spinner  animation="border" /></div>
              </Col>
            </Row>
          </Container>
      )
    }
    else {

      return (
          <Container fluid="lg" className="content_container">
            <Row>
              <Col>
                <h1 style={textStyle}>Analytics</h1>
              </Col>
            </Row>
            <Row>
               { (this.props.state.data.length > 0) && <AnalyticsStart user={this.props.state.user} data={this.props.state.data} setGapi={this.setGapi}/> }
               { (this.state.analyticsMerged) && <AnalyticsList data={this.state.localData} gapi={this.state.gapi}/> }
            </Row>
          </Container>
      )
    }
  }
}


export default Epdanalytics;


